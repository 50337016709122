import React, { useContext, useState } from "react";
import {
  StyledBoxListScroller,
  StyledBoxListScrollerItems,
  StyledBoxListScrollerItem,
  StyledBoxListScrollerItemButton,
  StyleInfoButton,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DataContext } from "../../contexts/DataContext.jsx";
import useTrack from "../../hooks/useTrack.js";
import { Button } from "@3beehivetech/components";

const BoxListB2B = ({ onClick }) => {
  const { trackCustomEvent } = useTrack();
  const boxes = [
    {
      image: "/2020/images/box-3bee/box-3bee-1.png",
      id: "box_3bee_12",
      name: "Box 3Bee Segui le Api",
      price: 11.99,
      available: false,
      features: [
        { text: "Cofanetto fisico illustrato sulle api e la biodiversità" },
        { text: "Adozione con certificato 3Bee." },
        { text: "Monitoraggio di un alveare e delle sue api per 1 anno." },
        { text: "App 3Bee per seguire la crescita dell'alveare." }],
    },
    {
      image: "/2020/images/box-3bee/box-3bee-2.png",
      id: "box_3Bee_24",
      name: "Box 3Bee da 24 e 99 euro",
      price: 24.99,
      available: true,
      features: [
        { text: "Cofanetto fisico illustrato sulle api e la biodiversità" },
        { text: "Adozione con certificato 3Bee con 500 grammi di miele." },
        { text: "Monitoraggio di un alveare e delle sue api per 1 anno." },
        { text: "App 3Bee per seguire la crescita dell'alveare." }],
    },
    {
      image: "/2020/images/box-3bee/box-3bee-3.png",
      id: "box_3Bee_32",
      name: "Box 3Bee da 34 e 99 euro",
      price: 34.99,
      available: true,
      features: [
        { text: "Cofanetto fisico illustrato sulle api e la biodiversità" },
        { text: "Adozione con certificato 3Bee con 1 kg di miele." },
        { text: "Monitoraggio di un alveare e delle sue api per 1 anno." },
        { text: "App 3Bee per seguire la crescita dell'alveare." }],
    },
  ];

  return (
    <StyledGridRow isFullMobile>
      <StyledBoxListScroller>
        <Scroller>
          <StyledBoxListScrollerItems>
            {boxes.map((box, index) => (
              <BoxListScrollerItem
                key={index}
                box={box}
                onClick={() => {
                  trackCustomEvent({
                    category: "BoxAdottatori",
                    action: "click",
                    label: `Box3Bee ${index + 1}`,
                  });
                  onClick();
                }}
              />
            ))}
          </StyledBoxListScrollerItems>
        </Scroller>
      </StyledBoxListScroller>
    </StyledGridRow>
  );
};

const BoxListScrollerItem = ({ box }) => {
  const { pageImages } = useContext(DataContext);
  const [info, setInfo] = useState(false);
  const imageData = pageImages.find((e) => e.path === box.image);

  return (
    <StyledBoxListScrollerItem>
      <div style="overflow: hidden; border-radius: 10px;">
        <div style={info ? "filter: blur(25px);" : ""}>
          <GatsbyImage
            image={getImage(imageData)}
            alt={imageData?.alt}
          />
        </div>
      </div>
      <StyleInfoButton onClick ={()=> {setInfo(!info);}}> <div style="color: black; position: relative; top: 20%; font-size: 18px; font-family: fantasy; font-weight: 100;"> ! </div> </StyleInfoButton>
      <div style={info ? "display: block; position: absolute; top: 40px; left: 20px; padding-right: 20px;" : "display: none;"}>
        <ul style="font-size: 20px; line-height: 22px; text-shadow: 1px 1px 5px black">
          {box.features.map((feature, id) => (
            <li style="margin-top: 10px; "key={id}> {feature.text} </li>
          ))}
        </ul>
      </div>
      <StyledBoxListScrollerItemButton>
        <Button
          className="snipcart-add-item"
          data-item-id={box.id}
          data-item-url="https://www.3bee.com/box/adottatori/"
          data-item-image={box.image}
          data-item-price={box.price}
          data-item-name={box.name}
          data-item-weight="100"
          data-item-min-quantity = "10"
          data-item-description="Verrà spedita alla sede della tua azienda e potrai regalarla a chi vuoi o usarla per proteggere le api tu stessa. Dopo il riscatto si attiverà un alveare 3Bee, la protezione delle api e la produzione del tuo miele."
          data-item-custom1-name="Biglietto personalizzato"
          data-item-custom1-options="No|Si con logo aziendale[10]"
          data-item-custom2-name="Vuoi adottare anche un Alveare aziendale"
          data-item-custom2-options="No|Si, vorrei essere contattato"
        >
          Acquista Ora
        </Button>
      </StyledBoxListScrollerItemButton>
    </StyledBoxListScrollerItem>
  );
};

export default BoxListB2B;
