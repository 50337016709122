import { useCallback } from "react";

class TagManager {
  static dataLayer({ dataLayer }) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
  }
}

const getEcommerceObject = (
  event,
  products,
  actionField,
) => {
  const defaults = {
    impressions: {
      event: "productImpressionHive",
      ecommerce: {
        currencyCode: "EUR",
        impressions: products,
      },
    },
    click: {
      event: "productClickHive",
      ecommerce: {
        click: {
          products: products,
        },
      },
    },
    detail: {
      event: "productDetailHive",
      ecommerce: {
        detail: {
          products: products,
        },
      },
    },
    addToCart: {
      event: "addToCartAdp",
      ecommerce: {
        add: {
          products: products,
        },
      },
    },
    checkout: {
      event: "checkoutAdp",
      ecommerce: {
        checkout: {
          products: products,
          actionField: actionField,
        },
      },
    },
    purchase: {
      event: "purchaseAdp",
      ecommerce: {
        purchase: {
          actionField: actionField,
          products: products,
        },
      },
    },
  };

  return defaults[event];
};

const cleanUp = () => {
  TagManager.dataLayer({
    dataLayer: { 
      event: "cleanup", 
      details: null, 
      ecommerce: null,
    },
  });
};

const useTrack = () => {
  const trackEcommerceEvent = useCallback(
    (
      eventType,
      data,
      actionField,
    ) => {
      cleanUp();
      const ecommerceObject = getEcommerceObject(eventType, data, actionField);
      return TagManager.dataLayer({
        dataLayer: { 
          ...ecommerceObject, 
        },
      });
    },
    [],
  );

  const trackCustomEvent = useCallback(({
    category,
    action,
    label,
    value,
  }) => {
    cleanUp();
    return TagManager.dataLayer({
      dataLayer: { 
        event: "AnalyticsEvent", 
        customData: {
          category,
          action,
          label,
          value, /// Must be a non-negative integer that will appear as the event value
        }, 
      },
    });
  }, []);

  const track404 = useCallback((path) => {
    cleanUp();

    return TagManager.dataLayer({
      dataLayer: {
        event: "404",
        details: {
          url: path,
        },
      },
    });
  }, []);

  return { 
    trackEcommerceEvent, 
    trackCustomEvent, 
    track404,
  };
};

export default useTrack;
